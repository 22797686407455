import React, { PropsWithChildren } from 'react';
import { NavLink } from 'react-router-dom';
import { Link, makeStyles } from '@material-ui/core';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarScrollWrapper,
  SidebarSpace,
  useSidebarOpenState,
} from '@backstage/core-components';
import { RBACSidebarItem } from '@spotify/backstage-plugin-rbac';

import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AppsIcon from '@material-ui/icons/Apps';
import HomeIcon from '@material-ui/icons/Home';
import ExtensionIcon from '@material-ui/icons/Extension';
import MapIcon from '@material-ui/icons/MyLocation';
import TokenIcon from '@material-ui/icons/ConfirmationNumber';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import BuildIcon from '@material-ui/icons/Build';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import BrushIcon from '@material-ui/icons/Brush';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import { GraphiQLIcon } from '@zeiss/plugin-zeiss-graphiql';

import { RequirePermission } from '@backstage/plugin-permission-react';
import {
  frontendGraphiqlPermission,
  frontendTokenPermission,
  frontendVersionDashboardPermission,
} from '@zeiss/backstage-plugin-frontend-permissions-common';
import { qetaReadPermission } from '@drodil/backstage-plugin-qeta-common';

import { ZeissFeatureFlagged } from '@zeiss/backstage-plugin-feature-flags';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link
        component={NavLink}
        to="/"
        underline="none"
        className={classes.link}
        aria-label="Home"
      >
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => {
  return (
    <SidebarPage>
      <Sidebar>
        <SidebarLogo />
        <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
          <SidebarSearchModal />
        </SidebarGroup>

        <SidebarItem icon={CreateComponentIcon} to="create" text="Create" />

        <SidebarDivider />

        <SidebarGroup label="Menu" icon={<MenuIcon />}>
          <SidebarScrollWrapper>
            <SidebarItem icon={HomeIcon} to="/" text="Home" />
            <SidebarItem icon={AppsIcon} to="catalog" text="App Catalog" />
            <SidebarItem
              icon={BrushIcon}
              to="design-catalog"
              text="Design Catalog"
            />
            <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs" />
            <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
            <SidebarItem icon={MapIcon} to="tech-radar" text="Tech Radars" />
            <RBACSidebarItem />
            <SidebarItem icon={DoneAllIcon} to="soundcheck" text="Soundcheck" />

            <SidebarDivider />

            <SidebarItem
              icon={AnnouncementIcon}
              to="announcements"
              text="Announcements"
            />
            <RequirePermission
              permission={qetaReadPermission}
              errorPage={<></>}
            >
              <SidebarItem icon={LiveHelpIcon} to="qeta" text="Q&A" />
            </RequirePermission>

            <SidebarDivider />

            <RequirePermission
              permission={frontendTokenPermission}
              errorPage={<></>}
            >
              <SidebarItem
                icon={TokenIcon}
                text="Token Retrieval"
                to="/token-retrieval"
              />
            </RequirePermission>
            <RequirePermission
              permission={frontendGraphiqlPermission}
              errorPage={<></>}
            >
              <SidebarItem icon={GraphiQLIcon} text="GraphiQL" to="/graphiql" />
            </RequirePermission>
            <RequirePermission
              permission={frontendVersionDashboardPermission}
              errorPage={<></>}
            >
              <SidebarItem
                icon={DynamicFeedIcon}
                to="version-dashboard"
                text="Deployments"
              />
            </RequirePermission>
            <ZeissFeatureFlagged name="library-check">
              <SidebarItem
                icon={ExtensionIcon}
                to="library-check"
                text="Libraries"
              />
            </ZeissFeatureFlagged>
            <SidebarItem icon={BuildIcon} to="devtools" text="DevTools" />
            <SidebarItem
              icon={AssignmentTurnedInIcon}
              to="entity-linter"
              text="Entity Linter"
            />
          </SidebarScrollWrapper>
        </SidebarGroup>

        <SidebarSpace />

        <SidebarGroup
          label="Settings"
          icon={<UserSettingsSignInAvatar />}
          to="/settings"
        >
          <SidebarSettings />
        </SidebarGroup>
      </Sidebar>
      {children}
    </SidebarPage>
  );
};
